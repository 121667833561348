import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BookmarkService } from 'src/app/@domain/bookmark/application/bookmark.service';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PagedComponent } from '../../../../../../@common/abstract/paged-component.abstract';
import { Page } from '../../../../../../@common/entities/page.interface';
import { loadingMap } from '../../../../../../@common/rxjs/loading-map';
import { Video } from '../../../../../../@domain/video/entities/video.interface';
import { PaginationComponent } from '../../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../../videos/video-item/video-item.component';

@Component({
    selector: 'app-user-bookmarks',
    templateUrl: './user-bookmarks.component.html',
    styleUrls: [ './user-bookmarks.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, VideoItemComponent, PaginationComponent, SpinnerComponent, AsyncPipe ]
})
export class UserBookmarksComponent extends PagedComponent {
    isGridView$: Observable<boolean>;
    bookmarkedLecturesPage$: Observable<Page<Video> | undefined>;
    pageSize = 8;

    constructor(
        private bookmarksService: BookmarkService,
        private itemsViewService: ItemsViewService,
        route: ActivatedRoute,
        router: Router
    ) {
      super(route, router);

        this.isGridView$ = this.itemsViewService.onChangeView();

        this.bookmarkedLecturesPage$ = this.observeParameters('page').pipe(
            loadingMap(({ page }) => this.bookmarksService.getBookmarkedLectures(this.pageSize, this.offset(page)))
        );
    }

}
