<div class="related-categories" *ngIf="!overview">
  <h4>Related categories</h4>
  <div class="categories">
    <div class="category" *ngFor="let category of categoriesToShow"
         [routerLink]="[AppRoutes.Categories, category.id]" [queryParams]="{ sort: 'published', hits: 'descending' }">
      <span>{{ category.name }}</span>
    </div>
  </div>
  <button *ngIf="categories.length > 8" class="btn show-more" (click)="showMoreOrLess()">
    {{ showMoreBtn ? "Show more" : "Show less" }}
  </button>
</div>

<div class="related-categories" *ngIf="overview">
  <div class="categories">
    <div class="category overview" *ngFor="let category of categoriesToShow">
      <span>{{ category.name }}</span>
    </div>
  </div>
  <button *ngIf="categories.length > 8" class="btn show-more" (click)="showMoreOrLess()">
    {{ showMoreBtn ? "Show more" : "Show less" }}
  </button>
</div>
