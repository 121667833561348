import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { booleanAttribute, ChangeDetectorRef, Component, computed, effect, ElementRef, EventEmitter, inject, input, Output, signal, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCheck, faChevronDown, faPlus, faSearch, } from '@fortawesome/free-solid-svg-icons';
import { ErrorComponent } from '../error/error.component';
import { SpinnerComponent } from '../spinner/spinner.component';

export interface DropdownItem {
    text: string | undefined;
    id: string;
    checked?: boolean;
}

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: [ './dropdown-menu.component.scss' ],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        FaIconComponent,
        FormsModule,
        SpinnerComponent,
        ErrorComponent,
        SlicePipe,
    ],
})
export class DropdownMenuComponent {
    private elRef = inject(ElementRef);
    private cdr = inject(ChangeDetectorRef);

    label = input.required();
    items = input.required<DropdownItem[] | null>();
    searchPlaceholder = input.required();
    selectedItems = input<(string | number)[] | null | undefined>();
    selectedItem = input<string | null | undefined>();
    multiselect = input(false, { transform: booleanAttribute });
    error = signal('');
    @Output() change = new EventEmitter<DropdownItem[]>();
    search = signal('');
    isOpen = signal(false);
    searchedItems = computed(() => this.searchItems(this.search(), this.items() ?? []));
    faSearch = faSearch;
    faChevronDown = faChevronDown;
    faCheck = faCheck;
    faPlus = faPlus;

    constructor() {
        let firstTime = true;

        effect(() => {
            // remove reference in future make sure that input items cannot store checked state
            if (this.items()?.length && firstTime) {
                this.items()?.forEach(i => i.checked = false);
                firstTime = false;
            }

            if (this.selectedItems() || this.selectedItem()) {
                this.items()?.forEach((i) => {
                    i.checked = this.selectedItems()?.some(x => x.toString() === i.id) ?? this.selectedItem() === i.id;
                });
            }
            this.cdr.detectChanges();
        });
        effect(() => {
            console.log({ isOpened: this.isOpen() });
        });
        effect(() => this.closeOnOutsideClick());
    }

    public toggleItem(item: DropdownItem) {
        if (!this.multiselect()) {
            this.items()?.forEach(i => {
                if (i.id !== item.id) i.checked = false;
            });

            this.isOpen.set(false);
        }

        this.error.set('');
        item.checked = !item.checked;
        this.change.emit(this.items()?.filter(i => i.checked) ?? []);
    }

    public clear() {
        this.items()?.forEach((i) => (i.checked = false));
        this.change.emit([]);
        this.error.set('');
    }

    public toggleDropdown() {
        this.isOpen.set(!this.isOpen());
    }

    private searchItems(search: string, items: DropdownItem[]) {
        return items
            .filter(i => i.text?.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                const indexA = a.text?.toLowerCase().indexOf(search.toLowerCase()) ?? -1;
                const indexB = b.text?.toLowerCase().indexOf(search.toLowerCase()) ?? -1;

                if (indexA === indexB) {
                    return (a.text ?? '').localeCompare(b.text ?? '');
                }
                return indexA - indexB;
            })
            .slice(0, 50)
    }

    public getLabel() {
        const selectedItems = this.items()?.filter(i => i.checked) ?? [];

        if (selectedItems.length > 3) {
            return `${ selectedItems.length } selected`;
        } else {
            return selectedItems.map(i => i.text).join(', ') || this.label();
        }
    }

    private closeOnOutsideClick() {
        if (this.isOpen()) {
            const listener = (e: Event) => {
                if (!this.elRef.nativeElement.contains(e.target)) {
                    this.isOpen.set(false);
                    window.removeEventListener('click', listener);
                }
            };

            window.addEventListener('click', listener);
        }
    }
}
