import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, shareReplay, take, throwError } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { UploadPresentation } from '../../upload/entities/video-data.interface';
import { SlideDto } from '../entities/presentation-slide.interface';
import { Slide } from '../entities/slide.interface';

@Injectable({
    providedIn: 'root',
})
export class SlidesService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    public getSlides(lectureId: number, language: string): Observable<Slide[]> {
        return this.getSlideDtos(lectureId, language).pipe(
            map(dtos => dtos.flatMap(slide => slide.timestamps.map(timestamp => ({
                    id: slide.id,
                    title: slide.title,
                    image: slide.image,
                    timestamp
                }))
            )),
        );
    }

    public getSlideDtos(lectureId: number, language: string): Observable<SlideDto[]> {
        return this.http.get<SlideDto[]>(
            `${ ApiPath.slides }/get_slides/?lecture=${ lectureId }&language=${ language }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get slides of lecture failed', e);
                return throwError(() => e);
            })
        );
    }

    public uploadSlidesFile(presentation: UploadPresentation) {
        return this.http.post(`${ ApiPath.slides }/`, presentation,
            {
                withCredentials: true,
                reportProgress: true,
                observe: 'events'
            }
        ).pipe(
            catchError(e => {
                this.logger.apiError('Upload presentation failed', e);
                return throwError(() => e);
            })
        );
    }

    public syncSlides(data: SlideDto[]): Observable<any> {
        return this.http.patch(`${ ApiPath.slides }/sync_slides/`, data, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Sync slides failed', e);
                    return throwError(() => e);
                })
            );
    }

    public deleteSlide(slideId: number) {
        return this.http.delete(`${ ApiPath.slides }/${ slideId }/`, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Delete slide failed', e);
                    return throwError(() => e);
                })
            ).subscribe();
    }

}
