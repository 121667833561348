import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from '../../../../../routing/app.routes';

@Component({
    selector: 'app-related-categories',
    templateUrl: './related-categories.component.html',
    styleUrls: [ './related-categories.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, RouterLink ]
})
export class RelatedCategoriesComponent implements OnInit {
  @Input() categories!: any;
  @Input() overview: boolean = false;
  categoriesToShow: any;
    AppRoutes = AppRoutes;
  restCategories: any;
  showMoreBtn: boolean = true;

  ngOnInit(): void {
    this.mixCategories();
  }

  mixCategories(): void {
    const isGreaterThanFour: boolean = this.categories.length > 6 ? true : false;
    this.showMoreBtn = isGreaterThanFour;

    if (isGreaterThanFour) {
      this.categoriesToShow = this.categories.filter(
        (category: any, index: number) => index < 6
      );
      this.restCategories = this.categories.filter(
        (category: any, index: number) => index >= 6
      );
    } else {
      this.categoriesToShow = this.categories;
    }
  }

  showMoreOrLess() {
    if (this.showMoreBtn) {
      this.categoriesToShow = this.categories;
    } else {
      this.categoriesToShow = this.categories.filter(
        (category: any, index: number) => index < 6
      );
    }
    //this.categoriesToShow.push(...this.restCategories);

    this.showMoreBtn = !this.showMoreBtn;
  }
  /*
    onReadMoreOrLess(): void {
    if (this.readMore) {
      this.lecturerDescription = this.stringByApi;
    } else {
      this.lecturerDescription = this.cutString(this.stringLen);
    }

    this.readMore = !this.readMore;
  }
  */
}
