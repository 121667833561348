import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { HistoryService } from 'src/app/@domain/history/history.service';
import { Page } from '../../../../../../@common/entities/page.interface';
import { History } from '../../../../../../@domain/history/history.interface';
import { AppRoutes } from '../../../../../../routing/app.routes';
import { SpinnerComponent } from '../../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../../videos/video-item/video-item.component';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: [ './history.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, VideoItemComponent, RouterLink, SpinnerComponent, AsyncPipe, SlicePipe ]
})
export class HistoryComponent {
  historyPage$: Observable<Page<History>>
    AppRoutes = AppRoutes;

  constructor(private historyService: HistoryService) {
    this.historyPage$ = this.historyService.getHistory('descending', 3, 0, 'visited')
      .pipe(
        tap(page => page.results = page.results?.filter(history => history?.lecture))
      );
  }

}
