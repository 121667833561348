<section class="container dashboard">
  <div class="d-flex name-edit">
      <h2 *ngIf="user() as profile">
          @if (profile.user?.first_name) {
              {{ profile.user.first_name }}'s Dashboard
          } @else {
              Your Dashboard
          }
      </h2>
      <a class="btn btn-edit rounded-full" routerLink="/{{ AppRoutes.Profile }}/edit">Edit profile</a>
  </div>

  <div>
    <div class="tabs mb-2">
        <a *ngFor="let item of navItems()"
           [class.active]="item.name === currentTab" [routerLink]="item.value">
        {{ item.name }}
      </a>
    </div>

      <app-dropdown [defaultName]="currentTab" [items]="navItems()" (onClick)="changeTab($event)" />
  </div>

  <router-outlet/>
</section>
