<div class="file-info-container">
    <div class="file-info">
        <div class="file-name ellipsis">{{ name() }}</div>
        @if (size()) {
            <p class="grey">{{ size() }}</p>
        }
    </div>

    <div class="separator">|</div>

    @if (file()) {
        <app-dropdown-menu label="Select language" searchPlaceholder="Search language" [items]="Languages"
                           [selectedItem]="language()" (change)="selectLanguage($event)"
        />
    } @else {
        <div class="file-language">{{ language() }}</div>
    }
</div>

<div class="file-actions">
    @if (uploadState() === UploadState.UPLOADING) {
        <p>{{ uploadProgress() }} %</p>
    }

    @if (uploadState() === UploadState.PROCESSING) {
        <div class="processing">
            Processing...
            <app-spinner [small]="true" />
        </div>
    }

    @if (uploadState() === UploadState.NOT_STARTED && file()) {
        <button class="btn upload-button" (click)="upload()">
            Upload
            <fa-icon [icon]="faUpload" />
        </button>
    }

    @if (uploadState() === UploadState.NOT_STARTED) {
        <button class="btn download-button" (click)="download()">
            Download
            <fa-icon [icon]="faDownload" />
        </button>

        <button class="btn btn-remove" (click)="remove()">
            Remove
            <fa-icon [icon]="faTimes" />
        </button>
    }
</div>
