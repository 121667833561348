import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { NewsService } from 'src/app/@domain/news/application/news.service';
import { News } from "../../../../../@domain/news/entities/news.interface";
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { NewsItemComponent } from '../../news/news-item/news-item.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: [ './news.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, NewsItemComponent, SpinnerComponent, AsyncPipe ]
})
export class NewsComponent {
  news$?: Observable<News[]>;

  constructor(private newsService: NewsService) {
    this.news$ = this.newsService.getAllNews(4, 0).pipe(
      map(news => news.results ?? [])
    )
  }

}
