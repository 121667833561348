<app-modal [tag]="Modal.CreateAuthor">
    <app-modal-header modalTitle="Add new author" (onClose)="onCloseModal()" />

    <app-modal-body>
        @if (!isLoading()) {
            <form [formGroup]="authForm" id="author-form" (ngSubmit)="createAuthor()" #form="ngForm">
                <div class="form-control">
                    <input type="text" placeholder="Author slug" maxlength="120" formControlName="slug" />
                    @if (slug?.invalid && !slug?.pristine || form?.submitted) {
                        @if (slug?.errors?.required) {
                            <app-error error="Slug is required" />
                        } @else if (slug?.errors?.slugExists) {
                            <app-error error="Slug is already taken" />
                        }
                    }
                </div>
                <div class="form-control">
                    <input type="text" placeholder="Author name" maxlength="120" formControlName="name" />
                    @if (name?.invalid && !name?.pristine || form?.submitted) {
                        @if (name?.errors?.required) {
                            <app-error error="Name is required" />
                        }
                    }
                </div>
                <div class="form-control">
                    <textarea type="text" class="description" placeholder="Author description" maxlength="120"
                      formControlName="description" rows="2"></textarea>
                    @if (description?.invalid && !description?.pristine || form?.submitted) {
                        @if (description?.errors?.required) {
                            <app-error error="Description is required" />
                        }
                    }
                </div>

                <h4 class="mb-1">Picture</h4>
                <div style="margin-bottom: 1rem">
                    <div class="dropzone">
                        <div class="icon">
                            <fa-icon [icon]="faImage" />
                        </div>
                        <p>
                            <a (click)="fileDropRef.click()" style="cursor: pointer">Upload a file</a>
                            or drag and drop
                        </p>
                        <span>PNG, JPG, GIF up to 10 MB</span>
                        <input type="file" #fileDropRef multiple hidden (change)="selectPhoto($event)" />
                    </div>
                    @if (file(); as file) {
                        <div style="margin-top: 0.5rem">
                            <span>File name: </span>{{ file.name }}
                        </div>
                    }
                </div>
            </form>

        } @else {
            <app-spinner class="mt-3" />
        }
    </app-modal-body>
    <app-modal-footer>
        <div class="d-flex footer">
            <button class="btn cancel" (click)="onCloseModal()">Cancel</button>
            <button class="author-create btn btn-danger" form="author-form" type="submit">
                Create
            </button>
        </div>
    </app-modal-footer>
</app-modal>
