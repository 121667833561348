<ng-container *ngLet="upcomingEvents$ | async as events">
  <div class="all-events">
    <div class="event" *ngFor="let event of events | slice: 0:3">
      <div class="date">
        <h3>{{ event.date | transformDateDay }}</h3>
        <p>{{ event.date | transformDateMonth }}</p>
      </div>
      <div class="text">
        <h5>{{ event.title }}</h5>
        <p>{{ event.date }}</p>
      </div>
    </div>
    <p class="italic mb-4" *ngIf="events?.length === 0">
      There are no upcoming events at the moment.
    </p>
  </div>
  <button *ngIf="events?.length" class="btn" style="margin-bottom: 3rem"
          [routerLink]="AppRoutes.Events" [queryParams]="{ sort: 'date', hits: 'descending' }">
    All events
    <fa-icon [icon]="faArrowRight"/>
  </button>
</ng-container>
