import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { PagedComponent } from '../../../../../@common/abstract/paged-component.abstract';
import { Page } from '../../../../../@common/entities/page.interface';
import { loadingMap } from '../../../../../@common/rxjs/loading-map';
import { UserService } from '../../../../../@domain/user/application/user.service';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../videos/video-item/video-item.component';

@Component({
    selector: 'app-user-uploaded-videos',
    templateUrl: './user-uploaded-videos.component.html',
    styleUrls: [ './user-uploaded-videos.component.scss' ],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        NgFor,
        VideoItemComponent,
        PaginationComponent,
        SpinnerComponent,
        AsyncPipe,
        SlicePipe,
    ],
})
export class UserUploadedVideosComponent extends PagedComponent {
    private userService = inject(UserService);

    uploadedLectures$: Observable<Page<Video> | undefined>
    user = this.userService.userProfile;
    pageSize = 7;
    faPlusCircle = faPlusCircle;

    constructor(
        private lecturesService: VideoService,
        router: Router,
        route: ActivatedRoute
    ) {
        super(route, router)

        this.uploadedLectures$ = this.observeParameters('page').pipe(
            loadingMap(({ page }) => this.lecturesService.getUserUploadedVideos(this.pageSize, this.offset(page)))
        )
    }

    goToUpload(): void {
        if (window.innerWidth < 1000) {
            confirm('Please use a desktop computer to upload content.')
        } else {
            this.router.navigate([ '/upload-video' ]);
        }
    }

}
