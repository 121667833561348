<div *ngIf="bookmarkedLecturesPage$ | async as bookmarkPage; else loading">
  <div class="mt-2 list-view">
    <app-video-item
            *ngFor="let lecture of bookmarkPage?.results; let even = even" [lecture]="lecture"
            [class.bg-light]="even" [isBookmark]="true"
    />

    <app-pagination [totalItems]="bookmarkPage.count" [pageSize]="pageSize"/>
  </div>

  <div class="italic" *ngIf="!bookmarkPage.results?.length">You do not have any saved bookmarks.</div>
</div>

<ng-template #loading>
  <app-spinner/>
</ng-template>
