<div *ngLet="playlistPage$ | async as playlistPage">
  <div class="table-sort mt-2">
      <button class="playlist-add btn btn-danger" (click)="openCreatePlaylistModal()">
      New playlist
      <fa-icon [icon]="faPlus"/>
    </button>
    <div class="sort">
      <!--  <app-sort></app-sort>
      <app-view></app-view> -->
    </div>
  </div>

  <div class="mt-2 {{ (isGridView$ | async) ? 'grid-4' : 'list-view' }}" *ngIf="playlistPage !== null">
      <app-playlist-item *ngFor="let playlist of playlistPage?.results" [playlist]="playlist" />
  </div>
  <div class="mt-2 italic" *ngIf="playlistPage?.results?.length === 0">
    You do not have any saved playlists.
  </div>
  <div *ngIf="playlistPage !== null">
      <app-pagination [totalItems]="playlistPage?.count" [pageSize]="pageSize" />
  </div>

  <div *ngIf="playlistPage === null" class="flex-center mt-5">
    <app-spinner/>
  </div>
</div>

<app-modal [tag]="Modal.CreatePlaylist">
  <app-modal-header modalTitle="Create a new playlist"/>
  <app-modal-body>
    <form>
      <div class="form-control">
        <input
          type="text"
          name="playlistTitle"
          placeholder="Playlist title"
          id="playlistTitle"
          maxlength="120"
          [(ngModel)]="playlistTitle"
          (ngModelChange)="onPlaylistTitleChange()"
        />
        <app-error [error]="playlistTitleError"/>
      </div>
      <!--     <h4 class="mb-1">Thumbnail</h4>
      <div style="margin-bottom: 1rem">
        <div class="dropzone">
          <div class="icon"><fa-icon [icon]="faImage"></fa-icon></div>
          <p>
            <a (click)="fileDropRef.click()" style="cursor: pointer"
              >Upload a file</a
            >
            or drag and drop
          </p>
          <span>PNG, JPG, GIF up to 10 MB</span>
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            multiple
            (change)="onThumbnailPhotoSelected($event)"
            style="display: none"
          />
        </div>
        <div *ngIf="thumbnailPicName" style="margin-top: 0.5rem">
          <span>File name: </span>{{ thumbnailPicName }}
        </div>
      </div> -->
    </form>
  </app-modal-body>
  <app-modal-footer>
    <div class="d-flex footer">
      <button class="btn cancle" (click)="onCloseModal()">Cancel</button>
      <button class="playlist-create btn btn-danger" (click)="createPlaylist()">Create</button>
    </div>
  </app-modal-footer>
</app-modal>
