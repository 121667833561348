import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { Event } from "../../../../../@domain/event/entities/event.interface";
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { EventItemComponent } from '../../events/event-item/event-item.component';
import { NgIf, NgFor, AsyncPipe, SlicePipe } from '@angular/common';

@Component({
    selector: 'app-events-section',
    templateUrl: './events-section.component.html',
    styleUrls: [ './events-section.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, EventItemComponent, SpinnerComponent, AsyncPipe, SlicePipe ]
})
export class EventsSectionComponent {
  events$: Observable<Event[]>

  constructor(private eventsService: EventService) {
    this.events$ = this.eventsService.getLatestEvents()
      .pipe(
        map(({results}) => results ?? [])
      )
  }

}
