import { Routes } from '@angular/router';
import { HomePageComponent } from '../@page/app/landing/home-page.component';
import { UserEditComponent } from '../@ui/content/forms/search/edit/user-edit.component';
import { ProfileDashboardComponent } from '../@ui/content/profile/profile-dashboard/profile-dashboard.component';
import { UserBookmarksComponent } from '../@ui/content/sections/user-profile-table/user-bookmarks/user-bookmarks/user-bookmarks.component';
import { UserEventsComponent } from '../@ui/content/sections/user-profile-table/user-events/user-events.component';
import { UserFollowingComponent } from '../@ui/content/sections/user-profile-table/user-following/user-following/user-following.component';
import { UserPlaylistComponent } from '../@ui/content/sections/user-profile-table/user-playlist/user-playlist/user-playlist.component';
import { UserUploadedVideosComponent } from '../@ui/content/sections/user-profile-table/user-uploaded-videos/user-uploaded-videos.component';
import { UploadForm1Component } from '../@ui/content/upload-content/upload-form1/upload-form1.component';
import { UploadForm2Component } from '../@ui/content/upload-content/upload-form2/upload-form2.component';
import { UploadForm3Component } from '../@ui/content/upload-content/upload-form3/upload-form3.component';
import { UploadForm4Component } from '../@ui/content/upload-content/upload-form4/upload-form4.component';
import { authGuardFn } from './guards/auth.guard';

export enum AppRoutes {
    Home = '',
    Register = 'register',
    Login = 'login',
    ForgotPassword = 'forgot-password',
    Profile = 'profile',
    Lectures = 'lectures',
    Company = 'company',
    Categories = 'categories',
    Events = 'events',
    News = 'news',
    Search = 'search',
    Slug = ':slug',
    Lecturer = 'lecturer',
    Playlists = 'playlists',
    History = 'history',

}

export const APP_ROUTES: Routes = [
    {
        path: AppRoutes.Home,
        pathMatch: 'full',
        component: HomePageComponent
    },
    {
        path: AppRoutes.Register,
        loadComponent: () => import('../@page/app/register/register.page')
    },
    {
        path: AppRoutes.Login,
        loadComponent: () => import('../@page/app/login/login.page')
    },
    {
        path: AppRoutes.ForgotPassword,
        loadComponent: () => import('../@page/app/forgot-password/forgot-password.page')
    },
    {
        path: AppRoutes.Profile,
        canActivate: [ authGuardFn ],
        loadChildren: () => [
            {
                path: 'edit',
                component: UserEditComponent
            },
            {
                path: 'tab',
                component: ProfileDashboardComponent,
                children: [
                    { path: 'videos', component: UserUploadedVideosComponent },
                    { path: 'playlists', component: UserPlaylistComponent },
                    { path: 'following', component: UserFollowingComponent },
                    { path: 'bookmarks', component: UserBookmarksComponent },
                    { path: 'events', component: UserEventsComponent },
                    { path: '**', redirectTo: 'playlists', pathMatch: 'full' }
                ]
            },
            {
                path: '**',
                redirectTo: 'tab',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: AppRoutes.Events,
        loadComponent: () => import('../@page/app/events/events.page'),
    },
    {
        path: AppRoutes.Lectures,
        loadComponent: () => import('../@page/app/lectures/lectures.page'),
    },
    {
        path: AppRoutes.News,
        loadComponent: () => import('../@page/app/news/news.page'),
    },
    {
        path: AppRoutes.Categories,
        loadComponent: () => import('../@page/app/categories/categories.page'),
        // canActivate: [],
        // children: []
    },
    {
        path: `${ AppRoutes.Categories }/:id`,
        loadComponent: () => import('../@page/app/category/category.page'),
        // canActivate: [],
        // children: []
    },
    {
        path: AppRoutes.History,
        loadComponent: () => import('../@page/app/history/history.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoutes.Playlists }/:id`,
        loadComponent: () => import('../@page/app/playlist/playlist.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoutes.Lecturer }/:id`,
        loadComponent: () => import('../@page/app/lecturer-profile/lecturer-profile.page'),
    },
    {
        path: 'lecture-agreement',
        loadComponent: () => import('../@page/app/lecture-agreement/lecture-agreement.page'),
    },
    {
        path: 'activate/:id/:token',
        loadComponent: () => import('../@page/app/activate-account/activate-account.page'),
    },
    {
        path: 'reset-password/:id/:token',
        loadComponent: () => import('../@page/app/password-change/password-change.page'),
    },
    {
        path: 'upload-video',
        loadComponent: () => import('../@page/app/upload/upload.page'),
        loadChildren: () => [
            { path: 'step/1', component: UploadForm1Component },
            { path: 'step/2', component: UploadForm2Component },
            { path: 'step/3', component: UploadForm3Component },
            { path: 'step/4', component: UploadForm4Component },
            { path: '**', redirectTo: 'step/1', pathMatch: 'full' }
        ]
    },
    {
        path: 'lecture/:slug/:language',
        loadComponent: () => import('../@page/app/synchronization/synchronization.page'),
    },
    {
        path: AppRoutes.Search,
        loadComponent: () => import('../@page/app/search-results/search-results.page')
    },
    {
        path: AppRoutes.Company,
        loadComponent: () => import('../@page/app/company/company.page')
    },
    {
        path: 'privacy-terms-of-use',
        loadComponent: () => import('../@page/app/privacy-terms-of-use/privacy-terms.page')
    },
    {
        path: AppRoutes.Slug,
        pathMatch: 'full',
        loadComponent: () => import('../@page/app/slug/slug.page')
    },
];
