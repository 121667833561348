import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Logger } from '../../../@common/log/logger';
import { SlugType } from '../entities/slug-type.enum';

@Injectable({
    providedIn: 'root'
})
export class SlugService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    public getSlugType(slug: string): Observable<SlugType | null> {
        const cachedSlugType = this.getFromLocalStorage(slug);

        return cachedSlugType ? of(cachedSlugType) : this.fetchSlugType(slug);
    }

    private fetchSlugType(slug: string): Observable<SlugType | null> {
        return this.http.get<{ type: SlugType }>(`${ environment.apiUrl }/slug/${ slug }/`).pipe(
            take(1),
            map(({ type }) => type),
            tap(type => {
                if (slug) {
                    this.setToLocalStorage(slug, type)
                }
            }),
            catchError(e => {
                if (e instanceof HttpResponse && e.status !== 404) {
                    this.logger.apiError('Get slug type failed', e);
                }

                return of(null);
            })
        );
    }

    private getFromLocalStorage(slug: string): SlugType | null {
        return localStorage.getItem('slug_' + slug) as SlugType | null;
    }

    private setToLocalStorage(slug: string, type: SlugType): void {
        localStorage.setItem('slug_' + slug, type);
    }

}
