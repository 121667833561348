import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../videos/video-item/video-item.component';

@Component({
    selector: 'app-trending-lectures',
    templateUrl: './trending-lectures.component.html',
    styleUrls: [ './trending-lectures.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, VideoItemComponent, SpinnerComponent, AsyncPipe ]
})
export class TrendingLecturesComponent {
    trendingLectures$: Observable<Video[]>;

    constructor(private lecturesService: VideoService) {
        this.trendingLectures$ = this.lecturesService.getMostViewedVideos()
      .pipe(
        map(({results}) => results ?? []),
      );
  }

}
