<div class="upload-section mt-2">
    <h2>Video upload</h2>
    <div class="dropzone" dragAndDrop (onDrop)="handleVideoDrop($event)">
        @if (uploadState() === UploadState.NOT_STARTED) {
            <div class="select-video">
                @if (lectureData().videos[0]) {
                    Drag and drop video to replace the current one
                } @else {
                    <p>Drag and drop video to upload</p>
                }
                <span>Video file</span>
                <input type="file" hidden #videoDrop multiple (change)="handleVideoSelect($event)" />
                <button class="btn-upload" (click)="videoDrop.click()">
                    Or choose file
                    <fa-icon [icon]="faUpload" />
                </button>
            </div>
            @if (this.videoFile()) {
                <div class="mt-1">Video is ready to upload!</div>
            }
        }
        @if (uploadState() === UploadState.UPLOADING) {
            <div class="upload-in-progress">
                <div class="top">{{ videoFile()?.name }}</div>
                <p-progressBar mode="indeterminate" [style]="{ height: '5px' }" color="#FF2E59" />
                <div class="bottom">
                    <p>Uploading...</p>
                    <p>{{ uploadProgress() }} %</p>
                </div>
            </div>
        }
        @if (uploadState() === UploadState.UPLOADED && lectureData().lectureId) {
            <div class="upload-finished">
                <div class="check">
                    <fa-icon [icon]="faCheck" />
                </div>
                <p class="success">Video upload Successful</p>
                <p class="instructions">
                    After uploading your video file, please follow the instructions for
                    synchronization between video and presentation. This will make the
                    navigation through video easier.
                </p>
            </div>
        }
    </div>

    <p class="terms-of-service">
        By uploading content to Videolectures.net you agree with our
        <span>Terms of Service</span> policy.
    </p>

    <div class="file-info mt-05">
        <div class="left">
            @if (videoData().fileName) {
                <div class="file-name">{{ videoData().fileName }}</div>
            }

            @if (videoData().videoSize) {
                <p class="grey">{{ videoData().videoSize }}</p>
            }
        </div>

        @if (uploadState() === UploadState.NOT_STARTED && videoFile()) {
            <div class="right">
                <button class="btn btn-remove" (click)="removeVideo()">
                    Remove Video
                    <fa-icon [icon]="faTimes" />
                </button>
            </div>
        }
    </div>

    <div *ngIf="uploadState() === UploadState.NOT_STARTED">
        <h2 style="margin-top: 3rem">Thumbnail</h2>
        <div class="images mt-2">
            @if (videoData().thumbnail.base64 || lectureData().thumbnail; as img) {
                <div class="image-preview">
                    <div class="left">
                        <div class="thumbnail-image">
                            <img class="profile-image" [src]="'data:image/jpg;base64,' + img" alt="video-thumbnail" />
                        </div>
                    </div>
                    <div class="right">
                        <button class="btn btn-remove" (click)="removeThumbnail()">
                            Remove Thumbnail
                            <fa-icon [icon]="faTimes" />
                        </button>
                    </div>
                </div>
            } @else {
                <div class="thumbnail-upload" (click)="thumbnailFile.click()" *ngIf="!lectureData().lectureId">
                    <fa-icon [icon]="faFileImage" />
                    <p>Click to upload image</p>
                    <input type="file" #thumbnailFile multiple hidden (change)="selectThumbnail($event)" />
                </div>
            }
        </div>
    </div>

    @if (videoData().thumbnail.base64 && uploadState() === UploadState.UPLOADED) {
        <p class="thumbnail-uploaded"><b>Thumbnail uploaded Successful</b></p>
    }

    <app-error [error]="errorMsg()" />

    @if (uploadState() === UploadState.NOT_STARTED) {
        <div class="upload-button">
            <button (click)="uploadLecture()">Upload video and thumbnail</button>
        </div>
    }
</div>

<div class="buttons mt-3">
    <button class="btn mt-2 mb-5 back" (click)="onClickBack()">Back</button>
    @if (uploadState() === UploadState.UPLOADED || lectureData().lectureId) {
        <button class="btn mt-2 mb-5 next" (click)="onClickNext()">Next</button>
    }
</div>
