import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgLetModule } from 'ng-let';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/@common/global-constants';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { PagedComponent } from '../../../../../../@common/abstract/paged-component.abstract';
import { Page } from '../../../../../../@common/entities/page.interface';
import { loadingMap } from '../../../../../../@common/rxjs/loading-map';
import { Modal } from '../../../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../../../@domain/modal/modal.service';
import { Playlist } from '../../../../../../@domain/playlist/entities/playlist.interface';
import { UserService } from '../../../../../../@domain/user/application/user.service';
import { ErrorComponent } from '../../../../../global/error/error.component';
import { ModalBodyComponent } from '../../../../../global/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from '../../../../../global/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../../../../global/modal/modal-header/modal-header.component';
import { ModalComponent } from '../../../../../global/modal/modal.component';
import { PaginationComponent } from '../../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../../global/spinner/spinner.component';
import { PlaylistItemComponent } from '../../../playlist-item/playlist-item/playlist-item.component';

@Component({
    selector: 'app-user-playlist',
    templateUrl: './user-playlist.component.html',
    styleUrls: [ './user-playlist.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgLetModule, FaIconComponent, NgIf, NgFor, PlaylistItemComponent, PaginationComponent, SpinnerComponent, ModalComponent, ModalHeaderComponent, ModalBodyComponent, FormsModule, ErrorComponent, ModalFooterComponent, AsyncPipe ]
})
export class UserPlaylistComponent extends PagedComponent {
    isGridView$?: Observable<boolean>;
    playlistPage$?: Observable<Page<Playlist> | undefined>;
    pageSize = 8;
    faPlus = faPlus;

    // Create a new playlist form
    playlistTitle: string = '';
    selectedThumbnailPic!: File;
    thumbnailPicBase64!: string;
    thumbnailPicOnHold: boolean = false;
    thumbnailPicFileType: boolean = false;
    thumbnailPicName: string = '';
    thumbnailPhotoOver!: boolean;

    playlistTitleError: string = '';

    constructor(
        private playlistService: PlaylistService,
        private userProfileService: UserService,
        private itemsViewService: ItemsViewService,
        private modalService: ModalService,
        route: ActivatedRoute,
        router: Router
    ) {
        super(route, router);

        this.isGridView$ = this.itemsViewService.onChangeView();
        this.playlistPage$ = this.observeParameters('page').pipe(
            loadingMap(({ page }) => this.playlistService.getPlaylists(this.pageSize, this.offset(page)))
        );
    }

    ngOnInit(): void {
        // Open modal window if user click create new playlist in the lecture page
        // TODO: convert to queryParam
        const openModalWindow = localStorage.getItem('createNewPlaylist');
        if (openModalWindow == '1') {
            setTimeout(() => {
                this.openCreatePlaylistModal()
                localStorage.removeItem('createNewPlaylist');
            }, 500);
        }
    }

    @HostListener('dragover', [ '$event' ])
    onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = true;
    }

    @HostListener('dragleave', [ '$event' ])
    public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = false;
    }

    @HostListener('drop', [ '$event' ])
    public ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = false;
        if (evt.dataTransfer.files.length > 0) {
            this.onThumbnailPhotoDropped(evt.dataTransfer.files[0]);
        }
    }

    openCreatePlaylistModal(): void {
        this.modalService.open(Modal.CreatePlaylist);
    }

    encodeFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = (event: any) => resolve(btoa(event.target.result.toString()));
        });
    }

    onThumbnailPhotoDropped(selectedThumbnailPic: any) {
        this.selectedThumbnailPic = selectedThumbnailPic;
        this.thumbnailPicName = this.selectedThumbnailPic.name;

        const ext = this.selectedThumbnailPic.name.substring(this.selectedThumbnailPic.name.lastIndexOf('.') + 1);
        if (
            ext.toLowerCase() == 'jpg' ||
            ext.toLowerCase() == 'jpeg' ||
            ext.toLowerCase() == 'png' ||
            ext.toLowerCase() == 'gif'
        ) {
            if (this.selectedThumbnailPic.size < 5000000) {
                this.thumbnailPicFileType = true;
            } else {
                window.alert('Please upload smaller file.');
                this.thumbnailPicFileType = false;
            }
        } else {
            window.alert('Please choose other file type.');
            this.thumbnailPicFileType = false;
        }

        if (this.thumbnailPicFileType) {
            this.encodeFile(this.selectedThumbnailPic).then((base64 => this.thumbnailPicBase64 = base64));
            this.thumbnailPicOnHold = true;
        }
    }

    onThumbnailPhotoSelected(event: any) {
        this.selectedThumbnailPic = <File>event.target.files[0];
        this.thumbnailPicName = this.selectedThumbnailPic.name;

        var ext = this.selectedThumbnailPic.name.substring(
            this.selectedThumbnailPic.name.lastIndexOf('.') + 1
        );

        if (
            ext.toLowerCase() == 'jpg' ||
            ext.toLowerCase() == 'jpeg' ||
            ext.toLowerCase() == 'png' ||
            ext.toLowerCase() == 'gif'
        ) {
            if (this.selectedThumbnailPic.size < 5000000) {
                this.thumbnailPicFileType = true;
            } else {
                window.alert('Please upload smaller file.');
                this.thumbnailPicFileType = false;
            }
        } else {
            window.alert('Please choose other file type.');
            this.thumbnailPicFileType = false;
        }

        if (this.thumbnailPicFileType) {
            this.encodeFile(this.selectedThumbnailPic).then(base64 => this.thumbnailPicBase64 = base64);

            this.thumbnailPicOnHold = true;
        }
    }

    checkPlaylist() {
        let isValid: boolean = true;

        if (!this.playlistTitle) {
            this.playlistTitleError = GlobalConstants.playlistTitleError;
            isValid = false;
        }

        return isValid;
    }

    onPlaylistTitleChange() {
        if (this.playlistTitle.length >= 120) {
            this.playlistTitleError = 'You reached maximum length of the title.';
        } else {
            this.playlistTitleError = '';
        }
    }

    createPlaylist() {
        const user = this.userProfileService.userProfile();

        if (!this.checkPlaylist() || !user) return;

        this.playlistService.addPlaylist(this.playlistTitle, user.user.id!)
            .subscribe(() => {
                this.playlistTitle = '';
                this.onCloseModal();
            });
    }

    onCloseModal(): void {
        this.modalService.close();
    }

    protected readonly Modal = Modal;
}
