import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgLetModule } from 'ng-let';
import { map, Observable } from 'rxjs';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { Event } from '../../../../../@domain/event/entities/event.interface';
import { AppRoutes } from '../../../../../routing/app.routes';
import { TransformDateDayPipe } from '../../../../global/pipes/transform-date-day.pipe';
import { TransformDateMonthPipe } from '../../../../global/pipes/transform-date-month.pipe';

@Component({
    selector: 'app-upcoming-events-section',
    templateUrl: './upcoming-events-section.component.html',
    styleUrls: [ './upcoming-events-section.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgLetModule, NgFor, NgIf, RouterLink, FaIconComponent, AsyncPipe, SlicePipe, TransformDateDayPipe, TransformDateMonthPipe ]
})
export class UpcomingEventsSectionComponent {
    upcomingEvents$: Observable<Event[]>;
    faArrowRight = faArrowRight;
    AppRoutes = AppRoutes;

    constructor(private eventService: EventService) {
        this.upcomingEvents$ = this.eventService.getUpcomingEvents().pipe(
            map(page => page.results ?? [])
        );
    }
}
