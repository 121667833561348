import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Playlist } from '../../../../../@domain/playlist/entities/playlist.interface';
import { AppRoutes } from '../../../../../routing/app.routes';

@Component({
    selector: 'app-playlist-item',
    templateUrl: './playlist-item.component.html',
    styleUrls: [ './playlist-item.component.scss' ],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        ImageDirective,
        AsyncPipe,
    ],
})
export class PlaylistItemComponent {
  @Input({required: true}) playlist!: Playlist;
  isGridView$: Observable<boolean>;
    AppRoutes = AppRoutes;

  constructor(private itemsViewService: ItemsViewService) {
    this.isGridView$ = this.itemsViewService.onChangeView();
  }
}
