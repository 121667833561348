<div class="table-sort mt-2">
    @if (user()?.user?.is_superuser) {
        <button class="playlist-add btn btn-danger" (click)="onNewEventClick()">
            New Event
            <fa-icon [icon]="faPlus" />
        </button>
    }
    <!--    <div class="sort">-->
    <!--        <app-sort/>-->
    <!--        <app-view/>-->
    <!--    </div>-->
</div>

<div class="mt-2 grid-4">
    <div class="italic"> You do not have any uploaded events.</div>
    <!--        <app-pagination [pageSize]="pageSize" [total]="eventPage.count" />-->

    <!--    <div *ngIf="!isLoaded" class="flex-center mt-5">-->
    <!--        <app-spinner/>-->
    <!--    </div>-->
</div>

<!-- <app-modal>
  <app-modal-header [modalTitle]="'Create a new playlist'"></app-modal-header>
  <app-modal-body>
    <form>
      <div class="form-control">
        <input
          type="text"
          name="playlistTitle"
          placeholder="Playlist title"
          id="playlistTitle"
          maxlength="120"
          [(ngModel)]="playlistTitle"
          (ngModelChange)="onPlaylistTitleChange()"
        />
        <app-error [error]="playlistTitleError"></app-error>
      </div>
          <h4 class="mb-1">Thumbnail</h4>
        <div style="margin-bottom: 1rem">
          <div class="dropzone">
            <div class="icon"><fa-icon [icon]="faImage"></fa-icon></div>
            <p>
              <a (click)="fileDropRef.click()" style="cursor: pointer"
                >Upload a file</a
              >
              or drag and drop
            </p>
            <span>PNG, JPG, GIF up to 10 MB</span>
            <input
              type="file"
              #fileDropRef
              id="fileDropRef"
              multiple
              (change)="onThumbnailPhotoSelected($event)"
              style="display: none"
            />
          </div>
          <div *ngIf="thumbnailPicName" style="margin-top: 0.5rem">
            <span>File name: </span>{{ thumbnailPicName }}
          </div>
        </div>
    </form>
  </app-modal-body>
  <app-modal-footer>
    <div class="d-flex footer">
      <button class="btn cancle" (click)="onCloseModal()">Cancel</button>

      <button
        class="playlist-create btn btn-danger"
        (click)="onCreatePlaylist()"
      >
        Create
      </button>
    </div>
  </app-modal-footer>
</app-modal> -->
