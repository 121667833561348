<div class="mt-2" *ngIf="authorPage$ | async as authorPage; else loading">
  <div class="list-view" *ngIf="authorPage?.results?.length; else noResults">
    <app-author-item *ngFor="let author of authorPage?.results; let even = even"
                     [class.bg-light]="even" [author]="author" [following]="true"/>
  </div>
  <app-pagination [totalItems]="authorPage?.count" [pageSize]="pageSize"/>

  <ng-template #noResults>
    <p class="italic mt-2">Your do not follow any authors.</p>
  </ng-template>
</div>

<ng-template #loading>
  <app-spinner/>
</ng-template>
