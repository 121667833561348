import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PagedComponent } from '../../../../../../@common/abstract/paged-component.abstract';
import { Page } from '../../../../../../@common/entities/page.interface';
import { loadingMap } from '../../../../../../@common/rxjs/loading-map';
import { AuthorService } from '../../../../../../@domain/author/application/author.service';
import { Author } from '../../../../../../@domain/author/entities/author.interface';
import { AuthorItemComponent } from '../../../../../global/author/author-item/author-item.component';
import { PaginationComponent } from '../../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../../global/spinner/spinner.component';

@Component({
    selector: 'app-user-following',
    templateUrl: './user-following.component.html',
    styleUrls: [ './user-following.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, AuthorItemComponent, PaginationComponent, SpinnerComponent, AsyncPipe ]
})
export class UserFollowingComponent extends PagedComponent {
    authorPage$: Observable<Page<Author> | undefined>;
    pageSize = 8;

    constructor(
      private authorService: AuthorService,
      route: ActivatedRoute,
      router: Router
    ) {
      super(route, router);

        this.authorPage$ = this.observeParameters('page').pipe(
            loadingMap(({ page }) => this.authorService.getFollowedAuthors(this.pageSize, this.offset(page)))
        );
    }

}
