<div class="container">
    @if (firstName?.value; as firstName) {
        <h2 class="mb-4">{{ firstName }}'s profile</h2>
    }
    <h4>Personal information</h4>
    <p style="color: #6b7280">Use a permanent address where you can receive mail.</p>
    <hr />

    <form [formGroup]="profileForm" #form1="ngForm">
        <div class="row">
            <div class="col-25">
                <label for="firstName">First name</label>
            </div>
            <div class="col-75">
                <input class="w-75" type="text" id="firstName" name="firstName" formControlName="firstName" />
                @if (firstName?.invalid && !firstName?.pristine || form1?.submitted) {
                    @if (firstName?.errors?.required) {
                        <app-error error="Name is required" />
                    }
                }
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col-25">
                <label for="lastName">Last Name</label>
            </div>
            <div class="col-75">
                <input class="w-75" type="text" id="lastName" name="lastName" formControlName="lastName" />
                @if (lastName?.invalid && !lastName?.pristine || form1?.submitted) {
                    @if (lastName?.errors?.required) {
                        <app-error error="Last name is required" />
                    }
                }
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col-25">
                <label for="email">E-mail address</label>
            </div>
            <div class="col-75">
                <input class="w-75" id="email" name="email" formControlName="email"
                       pTooltip="E-mail cannot be changed!" />
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col-25">
                <label for="photo">Photo</label>
            </div>
            <div class="col-75">
                <div class="profile-pic d-flex">
                    @if (profilePictureBase64()) {
                        <img [src]="'data:image/jpg;base64,' + profilePictureBase64()!" alt="new profile image" />
                    } @else {
                        <img [src]="image?.value || './assets/character.png'" alt="profile image" />
                    }
                    <input type="file" hidden (change)="onProfilePicSelected($event)" accept="image/*" #fileInput />
                    <button class="btn rounded-full ms-1 me-1" style="white-space: nowrap" (click)="fileInput.click()">
                        Change
                    </button>
                    <span style="word-break: break-word" *ngIf="selectedPictureFile()">
                            File name: {{ selectedPictureFile()!.name }}
                        </span>
                </div>
            </div>
        </div>
        <hr />

        <div class="row mb-2">
            <div class="col-25">
                <label for="photo">Cover photo</label>
            </div>
            <div class="col-75">
                <div class="dropzone">
                    <div class="icon">
                        <fa-icon [icon]="faImage" />
                    </div>
                    <p>
                        <a (click)="fileDropRef.click()" style="cursor: pointer;">Upload a file</a>
                        or drag and drop
                    </p>
                    <span>PNG, JPG, GIF up to 10 MB</span>
                    <input type="file" #fileDropRef id="fileDropRef" multiple hidden
                           (change)="selectCoverPhoto($event)" />
                </div>
                @if (coverPicture()) {
                    <span>File name: </span>{{ coverPicture()!.name }}
                }
            </div>
        </div>

        @if (!isLoadingProfile()) {
            <button type="submit" [disabled]="form1.invalid" class="btn btn-update mb-4" (click)="updateProfile()">
                Update profile
            </button>
        } @else {
            <app-spinner />
        }
    </form>

    <form [formGroup]="passwordForm" #form2="ngForm">
        <h4 style="margin-bottom: 20px">Security</h4>
        <hr />
        <div class="row">
            <div class="col-25">
                <label for="oldPassword">Old password</label>
            </div>
            <div class="col-75 form-control">
                <input class="w-75" type="password" name="oldPassword" formControlName="oldPassword" />
                @if (oldPassword?.invalid && !oldPassword?.pristine || form2?.submitted) {
                    @if (oldPassword?.errors?.required) {
                        <app-error error="Password is required" />
                    }
                }
            </div>
        </div>

        <hr />
        <div class="row">
            <div class="col-25">
                <label>New password</label>
            </div>
            <div class="col-75 form-control">
                <input class="w-75" type="password" name="newPassword" formControlName="newPassword" />
                @if (newPassword?.invalid && !newPassword?.pristine || form2?.submitted) {
                    @if (newPassword?.errors?.required) {
                        <app-error error="Password is required" />
                    } @else if (newPassword?.errors?.weakPassword) {
                        <app-error
                                error="Use at least 6 characters with one upper case, number and special character." />
                    }
                }
            </div>
        </div>

        <hr />
        <div class="row">
            <div class="col-25">
                <label>Retype new password</label>
            </div>
            <div class="col-75 form-control">
                <input class="w-75" type="password" name="newPassword2" formControlName="rePassword" />
                @if (rePassword?.invalid && !rePassword?.pristine || form1?.submitted) {
                    @if (rePassword?.errors?.required) {
                        <app-error error="Repeat password is required" />
                    } @else if (rePassword?.errors?.notSame) {
                        <app-error error="Passwords do not match" />
                    }
                } @else if (errorMessage()) {
                    <app-error [error]="errorMessage()" />
                }
            </div>
        </div>

        @if (!isLoadingPassword()) {
            <button type="submit" [disabled]="form2?.invalid" class="btn btn-update mt-2 mb-4"
                    (click)="updatePassword()">
                Update password
            </button>
        } @else {
            <app-spinner />
        }
    </form>

    <!--    <div class="box mb-5" >
      <h4>Delete your account</h4>
      <p>
        Once you delete your account, you will lose all data associated with it.
      </p>
      <button class="btn mt-1">Delete account</button>
    </div> -->
</div>
