import { Component, HostListener, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Observable, ReplaySubject } from 'rxjs';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PagedComponent } from '../../../../../@common/abstract/paged-component.abstract';
import { UserService } from '../../../../../@domain/user/application/user.service';

@Component({
    selector: 'app-user-events',
    templateUrl: './user-events.component.html',
    styleUrls: [ './user-events.component.scss' ],
    standalone: true,
    imports: [ FaIconComponent ],
})
export class UserEventsComponent extends PagedComponent {
    private userService = inject(UserService);
    pageSize: number = 8;
    faPlus = faPlus;

    user = this.userService.userProfile;
    eventTitle: string = '';
    selectedThumbnailPic!: File;
    thumbnailPicBase64!: string;
    thumbnailPicOnHold: boolean = false;
    thumbnailPicFileType: boolean = false;
    thumbnailPicName: string = '';
    thumbnailPhotoOver!: boolean;

    eventTitleError: string = '';

    alertType: string = 'Please choose other file type.';
    alertSize: string = 'Please upload smaller file.';

    @HostListener('dragover', [ '$event' ]) onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = true;
    }

    @HostListener('dragleave', [ '$event' ])
    public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = false;
    }

    @HostListener('drop', [ '$event' ])
    public ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.thumbnailPhotoOver = false;
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onThumbnailPhotoDropped(files[0]);
        }
    }

    constructor(
        private itemsViewService: ItemsViewService,
        router: Router,
        route: ActivatedRoute,
    ) {
      super(route, router);

    }

    onNewEventClick() {
        alert("not implemented")
    }

    base64encode(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event: any) =>
            result.next(btoa(event.target.result.toString()));
        return result;
    }

    onThumbnailPhotoDropped(selectedThumbnailPic: any) {
        this.selectedThumbnailPic = selectedThumbnailPic;
        this.thumbnailPicName = this.selectedThumbnailPic.name;

        var ext = this.selectedThumbnailPic.name.substring(
            this.selectedThumbnailPic.name.lastIndexOf('.') + 1
        );
        if (
            ext.toLowerCase() == 'jpg' ||
            ext.toLowerCase() == 'jpeg' ||
            ext.toLowerCase() == 'png' ||
            ext.toLowerCase() == 'gif'
        ) {
            if (this.selectedThumbnailPic.size < 5000000) {
                this.thumbnailPicFileType = true;
            } else {
                window.alert(this.alertSize);
                this.thumbnailPicFileType = false;
            }
        } else {
            window.alert(this.alertType);
            this.thumbnailPicFileType = false;
        }

        if (this.thumbnailPicFileType) {
            this.base64encode(this.selectedThumbnailPic).subscribe((base64) => {
                this.thumbnailPicBase64 = base64;
            });

            this.thumbnailPicOnHold = true;
        }
    }

    onThumbnailPhotoSelected(event: any) {
        this.selectedThumbnailPic = <File>event.target.files[0];
        this.thumbnailPicName = this.selectedThumbnailPic.name;

        var ext = this.selectedThumbnailPic.name.substring(
            this.selectedThumbnailPic.name.lastIndexOf('.') + 1
        );

        if (
            ext.toLowerCase() == 'jpg' ||
            ext.toLowerCase() == 'jpeg' ||
            ext.toLowerCase() == 'png' ||
            ext.toLowerCase() == 'gif'
        ) {
            if (this.selectedThumbnailPic.size < 5000000) {
                this.thumbnailPicFileType = true;
            } else {
                window.alert(this.alertSize);
                this.thumbnailPicFileType = false;
            }
        } else {
            window.alert(this.alertType);
            this.thumbnailPicFileType = false;
        }

        if (this.thumbnailPicFileType) {
            this.base64encode(this.selectedThumbnailPic).subscribe((base64) => {
                this.thumbnailPicBase64 = base64;
            });

            this.thumbnailPicOnHold = true;
        }
    }

}
