<div class="flex-between-center mt-3">
    <h2>General information</h2>
    <button class="btn btn-danger" (click)="openImportModal()">Import</button>
</div>
<div class="form-card mt-2">
    <p class="sub-title">Video Title</p>
    <div class="form-control">
        <textarea class="title" type="text" placeholder="Video title" [(ngModel)]="uploadData().title"
                  (ngModelChange)="titleError = ''"></textarea>
        <app-error [error]="titleError" />
    </div>
    <p class="sub-title">Video Description</p>
    <div class="form-control">
    <textarea class="description" type="text" placeholder="Video description"
              [(ngModel)]="uploadData().description" (ngModelChange)="descriptionError = ''"></textarea>
        <app-error [error]="descriptionError" />
    </div>
    <p class="sub-title">Video Slug</p>
    <div class="form-control">
    <textarea class="slug" type="text" placeholder="Video slug" [(ngModel)]="uploadData().slug"
              (ngModelChange)="slugError = ''" pTooltip="Slug can not be modified" tooltipPosition="bottom"
              [disabled]="!!uploadData().lectureId" [tooltipDisabled]="!uploadData().lectureId"
    ></textarea>
        <app-error [error]="slugError" />
    </div>
    <div class="option-row">
        <div class="option">
            <p class="sub-title mb-2">Video Type</p>
            <app-dropdown-menu #typeMenu label="Select video type" searchPlaceholder="Search for type"
                               [items]="DropdownOptions.types" (change)="onChangeTypes($event)"
                               [selectedItem]="uploadData().type"
            />
        </div>
        <div class="option">
            <p class="sub-title mb-2">Video Language</p>
            <app-dropdown-menu #langMenu label="Select video language" searchPlaceholder="Search for language"
                               [items]="DropdownOptions.language" (change)="onChangeLanguage($event)"
                               [selectedItem]="uploadData().languageId"
            />
        </div>
    </div>

    <h2 class="mb-2 mt-2">Advanced information</h2>
    <div class="option-row">
        <div class="option">
            <p class="sub-title mb-2">Credits</p>
            <app-dropdown-menu #authorsMenu label="Select the authors" searchPlaceholder="Search for authors"
                               multiselect [items]="authorItems$ | async" (change)="onChangeAuthors($event)"
                               [selectedItems]="uploadData().authorIds">
                <button class="add-new" (click)="createAuthor()">
                    Add new author
                </button>
            </app-dropdown-menu>
        </div>
        <div class="option">
            <p class="sub-title mb-2">Categories</p>
            <app-dropdown-menu #categoriesMenu label="Select the categories" searchPlaceholder="Search for categories"
                               multiselect [items]="categoryItems$ | async" (change)="onChangeCategories($event)"
                               [selectedItems]="uploadData().categoryIds"
            />
        </div>
    </div>
</div>
<div class="option-row">
    <div class="option">
        <p class="sub-title mb-2">Date Recorded</p>
        <div class="form-control video-type">
            <input type="date" name="date"
                   [ngModel]="uploadData().date | date:'yyyy-MM-dd'"
                   (ngModelChange)="uploadData().date = $event"
            />
        </div>
    </div>
    <div class="option">
        <p class="sub-title mb-2">Event</p>
        <app-dropdown-menu #eventMenu label="Select the event" searchPlaceholder="Search for event"
                           [items]="eventItems$ | async" (change)="onChangeEvent($event)"
                           [selectedItem]="uploadData().eventId?.toString()"
        />
    </div>
</div>

<h2 class="mb-2 mt-2">Privacy</h2>
<div class="privacy">
    <button class="privacy-option" (click)="onToggle(true)" [class.active]="uploadData().public">
        <input type="radio" id="publicTrue" name="privacy" [checked]="uploadData().public" />
        <div>
            <label for="publicTrue">Public access</label>
            <p>This project would be available to anyone who has the link</p>
        </div>
    </button>
    <button class="privacy-option" (click)="onToggle(false)" [class.active]="!uploadData().public">
        <input type="radio" id="publicFalse" name="privacy" [checked]="!uploadData().public" />
        <div>
            <label for="publicFalse">Private</label>
            <p>You are the only one able to access this project</p>
        </div>
    </button>
</div>

<hr>
<h2 class="mb-2 mt-2">Draft</h2>
<div class="privacy">
    <button class="privacy-option" (click)="uploadData().draft = true" [class.active]="uploadData().draft">
        <input type="radio" id="draftTrue" name="draft" [checked]="uploadData().draft" />
        <div>
            <label for="draftTrue">Draft mode</label>
            <p>This lecture will be in draft</p>
        </div>
    </button>
    <button class="privacy-option" (click)="uploadData().draft = false" [class.active]="!uploadData().draft">
        <input type="radio" id="draftFalse" name="draft" [checked]="!uploadData().draft" />
        <div>
            <label for="publicFalse">Publish</label>
            <p>This lecture will be published</p>
        </div>
    </button>
</div>
<hr>

<div class="buttons">
    <button class="btn mt-2 mb-5" (click)="onClickNext()">Next</button>
</div>

<app-create-author-modal />
<app-load-lecture-modal (onImport)="importVideo($event)" />
