import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../videos/video-item/video-item.component';

@Component({
    selector: 'app-latest-lectures',
    templateUrl: './latest-lectures.component.html',
    styleUrls: [ './latest-lectures.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, NgFor, VideoItemComponent, SpinnerComponent, AsyncPipe ]
})
export class LatestLecturesComponent {
    lectures$: Observable<Video[]>
  faArrowRight = faArrowRight;

    constructor(private lecturesService: VideoService) {
        this.lectures$ = this.lecturesService.getLatestVideos().pipe(
      map(({results}) => results ?? [])
    );
  }

}
