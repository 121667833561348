import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ImageDirective } from '../../../@common/DOM/image.directive';
import { Modal } from '../../../@domain/modal/modal.enum';
import { ModalService } from '../../../@domain/modal/modal.service';
import { UserService } from '../../../@domain/user/application/user.service';
import { CategoriesComponent } from '../../../@ui/content/sections/landing-page/categories/categories.component';
import { EventsSectionComponent } from '../../../@ui/content/sections/landing-page/events-section/events-section.component';
import { HistoryComponent } from '../../../@ui/content/sections/landing-page/history/history/history.component';
import { LatestLecturesComponent } from '../../../@ui/content/sections/landing-page/latest-lectures/latest-lectures.component';
import { NewsComponent } from '../../../@ui/content/sections/landing-page/news/news.component';
import { TrendingLecturesComponent } from '../../../@ui/content/sections/landing-page/trending-lectures/trending-lectures.component';
import { UpcomingEventsSectionComponent } from '../../../@ui/content/sections/landing-page/upcoming-events-section/upcoming-events-section.component';
import { ModalBodyComponent } from '../../../@ui/global/modal/modal-body/modal-body.component';
import { ModalComponent } from '../../../@ui/global/modal/modal.component';
import { SectionHeaderComponent } from '../../../@ui/global/section-header/section-header.component';
import { SectionComponent } from '../../../@ui/global/section/section.component';
import { AppRoutes } from '../../../routing/app.routes';

@Component({
    selector: 'app-landing',
    templateUrl: './home-page.component.html',
    styleUrls: [ './home-page.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ SectionComponent, CategoriesComponent, NgIf, HistoryComponent, LatestLecturesComponent, TrendingLecturesComponent, EventsSectionComponent, SectionHeaderComponent, NewsComponent, UpcomingEventsSectionComponent, ModalComponent, ModalBodyComponent, FaIconComponent, RouterLink, ImageDirective, AsyncPipe ]
})
export class HomePageComponent implements AfterViewInit {
    private userService = inject(UserService);
    private modalService = inject(ModalService);
    private cdr = inject(ChangeDetectorRef);

    @ViewChild(ModalComponent) modal?: ModalComponent;
    user = this.userService.userProfile;
    isAuthenticated = this.userService.isAuthenticated;
    AppRoutes = AppRoutes;
    faArrowRight = faArrowRight;
    faTimes = faTimes;

    ngAfterViewInit(): void {
        if (localStorage.getItem('seenModal') !== 'true') {
            this.modalService.open(Modal.WelcomeNotice);
        }

        this.cdr.detectChanges();
    }

    onCloseModal(): void {
        this.modalService.close();
        localStorage.setItem('seenModal', 'true');
    }

    protected readonly Modal = Modal;
}
