import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SectionHeaderComponent } from '../section-header/section-header.component';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html',
    styleUrls: [ './section.component.scss' ],
    imports: [ SectionHeaderComponent ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class SectionComponent {
    @Input({ required: true }) header!: string;
    @Input() link?: string;
    @Input() linkParams?: Record<string, string>;
}
